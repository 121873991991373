<div class="price-analysis-page">
  @if (loading()) {
    <app-loader-global class="loader" />
  } @else {
    @if (isMobile()) {
      <app-mobile-header variety="center">
        <!-- todo -->
        <!-- <app-link-back [link]="routeProfile" variety="absolute" /> -->
        <app-title>Аналитика цен</app-title>
      </app-mobile-header>
    } @else {
      <app-title class="title">Аналитика цен</app-title>
    }

    <div class="view-by-block">
      <a
        routerLink="."
        [queryParams]="{ view_by: 'trader' }"
        class="view-by left"
        [class.active]="viewBy() === 'trader'"
        >Цены объявлений</a
      >
      <a
        routerLink="."
        [queryParams]="{ view_by: 'recycler' }"
        class="view-by middle"
        [class.active]="viewBy() === 'recycler'"
        >Цены от зернообработчиков</a
      >
      <a
        routerLink="."
        [queryParams]="{ view_by: 'mixed' }"
        class="view-by right"
        [class.active]="viewBy() === 'mixed'"
        >Сравнить обе рыночных цены</a
      >
    </div>

    <div class="accordion-header">
      <!-- todo просто фильтр для адаптива -->
      <div class="subtitle">Фильтр по объявлениям</div>
      <app-button
        variety="only-text"
        (pressed)="
          panelOpenState()
            ? panelOpenState.set(false)
            : panelOpenState.set(true)
        "
        class="accordion-btn"
        >{{
          panelOpenState() ? "Скрыть фильтр" : "Показать фильтр"
        }}</app-button
      >
    </div>
    <mat-accordion class="price-analysis-accordion">
      <mat-expansion-panel
        [expanded]="panelOpenState()"
        class="price-analysis-panel"
      >
        <app-price-analysis-filter />
      </mat-expansion-panel>
      <hr />
    </mat-accordion>

    <div class="subtitle adaptive">{{ historyTitle() }}</div>
    <div class="histories">
      @if (viewBy() === "trader") {
        <app-price-history
          variety="announcements"
          title="Средняя цена"
          [history]="{ min: 1, max: 100, avg: 50 }"
        />
      }

      @if (viewBy() === "recycler") {
        <app-price-history
          variety="recycles"
          title="Средняя цена"
          [history]="{ min: 100, max: 1000, avg: 500 }"
        />
      }

      @if (viewBy() === "mixed") {
        <app-price-history
          variety="announcements"
          orientation="vertically"
          title="Средняя цена по объявлениям"
          [history]="{ min: 1, max: 100, avg: 50 }"
        />
        <app-price-history
          variety="recycles"
          orientation="vertically"
          title="Средняя цена от зернопобработчиков"
          [history]="{ min: 1, max: 100, avg: 50 }"
        />
      }
    </div>

    <div class="dates-group">
      <!-- <mat-form-field>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field> -->

      <mat-select
        #groupBySelect="matSelect"
        id="group_by"
        name="group_by"
        class="select"
        [class.opened]="groupBySelect.panelOpen"
        [class.error]=""
        panelClass="select-list"
        placeholder="Группировка"
      >
        @for (group of priceAnalysisGroupingList; track group.id) {
          <mat-option [value]="group.id" class="select-list-option">{{
            group.name
          }}</mat-option>
        }
      </mat-select>
    </div>

    <div>Красивые графики</div>
  }
</div>
