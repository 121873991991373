<div class="price-history" [class.vertically]="orientation() === 'vertically'">
  <div
    class="avg-block"
    [class.recycles]="variety() === 'recycles'"
    [class.vertically]="orientation() === 'vertically'"
  >
    {{ title() }}
    <span>{{ history().avg | number: "1.0" : "ru-Ru" }} руб./т</span>
  </div>
  <div class="history-block">
    История цен
    <span
      >от {{ history().min | number: "1.0" : "ru-Ru" }} руб до
      {{ history().max | number: "1.0" : "ru-Ru" }} руб</span
    >
  </div>
</div>
