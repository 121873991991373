import { Component } from '@angular/core';

// import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { SearchComponent } from '@components/ui/search/search.component';

@Component({
  selector: 'app-price-analysis-filter',
  standalone: true,
  imports: [MatSelectModule, MatRadioModule, SearchComponent],
  templateUrl: './price-analysis-filter.component.html',
  styleUrl: './price-analysis-filter.component.scss',
})
export class PriceAnalysisFilterComponent {
  // Поиск в списке зерновых культур
  onSearch(search: string) {
    console.info(search);
    // if (search) {
    //   this.filterCereals.set(
    //     this.cereals().filter((cereal) =>
    //       cereal.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    //     ),
    //   );
    // } else {
    //   this.filterCereals.set(this.cereals());
    // }
  }
}
