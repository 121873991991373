import { Component, input } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { TPriceHistory } from '../core/priceAnalysisTypes';

@Component({
  selector: 'app-price-history',
  standalone: true,
  imports: [DecimalPipe],
  templateUrl: './price-history.component.html',
  styleUrl: './price-history.component.scss',
})
export class PriceHistoryComponent {
  variety = input<'announcements' | 'recycles'>('announcements');
  orientation = input<'horizontally' | 'vertically'>('horizontally');
  title = input.required<string>();
  history = input.required<TPriceHistory>();
}
