<div class="price-analysis-filter-item">
  <label for="category_code">Категория:</label>
  <mat-select
    #categorySelect="matSelect"
    formControlName="category_code"
    id="category_code"
    name="category_code"
    class="select short"
    [class.opened]="categorySelect.panelOpen"
    [class.error]=""
    panelClass="select-list"
    placeholder="Категория"
  >
    <!-- @for (category of categories(); track category) {
              <mat-option [value]="category.code" class="select-list-option">{{
                category.label
              }}</mat-option>
            } -->
  </mat-select>
</div>

<div class="price-analysis-filter-item">
  <label for="cereal_id">Зерновая культура:</label>
  <mat-select
    #cerealSelect="matSelect"
    formControlName="cereal_id"
    id="cereal_id"
    name="cereal_id"
    class="select long"
    [class.opened]="cerealSelect.panelOpen"
    [class.error]=""
    panelClass="select-list"
    placeholder="Зерновая культура"
    [typeaheadDebounceInterval]="500"
  >
    <app-search
      placeholder="Поиск по культурам"
      variety="select"
      (searchText)="onSearch($event)"
    />
    <!-- @if (filterCereals().length > 0) {
              @for (cereal of filterCereals(); track cereal.id) {
                <mat-option
                  key="cereal.id"
                  [value]="cereal.id"
                  class="select-list-option"
                  >{{ cereal.name }}</mat-option
                >
              }
            } @else {
              <div class="empty-list">Культуры не найдены</div>
            } -->
  </mat-select>
</div>

<div class="price-analysis-filter-item">
  <label for="regions">Регионы:</label>
  <mat-select
    #regionsSelect="matSelect"
    formControlName="regions"
    id="regions"
    name="regions"
    class="select long"
    [class.opened]="regionsSelect.panelOpen"
    [class.error]=""
    panelClass="select-list"
    placeholder="Регионы"
    [typeaheadDebounceInterval]="500"
  >
    <app-search
      placeholder="Поиск по регионам"
      variety="select"
      (searchText)="onSearch($event)"
    />
    <!-- @if (filterCereals().length > 0) {
              @for (cereal of filterCereals(); track cereal.id) {
                <mat-option
                  key="cereal.id"
                  [value]="cereal.id"
                  class="select-list-option"
                  >{{ cereal.name }}</mat-option
                >
              }
            } @else {
              <div class="empty-list">Культуры не найдены</div>
            } -->
  </mat-select>
</div>

<div class="price-analysis-filter-item">
  <label for="type">Тип объявления:</label>
  <mat-radio-group
    id="type"
    name="type"
    formControlName="type"
    aria-label="type"
    class="items radio-label"
  >
    <mat-radio-button id="sell" name="sell" value="sell"
      >ПРОДАЖА
    </mat-radio-button>
    <mat-radio-button id="buy" name="buy" value="buy">ПОКУПКА</mat-radio-button>
  </mat-radio-group>
</div>
