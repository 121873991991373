import { Component, computed, inject, signal } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';

import { LoaderGlobalComponent } from '@components/common/loader-global/loader-global.component';
import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { TitleComponent } from '@components/common/title/title.component';
import { LinkBackComponent } from '@components/ui/link-back/link-back.component';
import { PriceHistoryComponent } from './price-history/price-history.component';
import { PriceAnalysisFilterComponent } from './price-analysis-filter/price-analysis-filter.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { priceAnalysisGroupingList } from '@settings/constants';
import { TViewBy } from './core/priceAnalysisTypes';

import { AuthService } from '@services/auth.service';
import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-price-analysis',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatExpansionModule,
    LoaderGlobalComponent,
    MobileHeaderComponent,
    LinkBackComponent,
    TitleComponent,
    PriceHistoryComponent,
    PriceAnalysisFilterComponent,
    ReactiveFormsModule,
    ButtonComponent,
    RouterLink,
  ],
  templateUrl: './price-analysis.component.html',
  styleUrl: './price-analysis.component.scss',
})
export class PriceAnalysisComponent {
  authService = inject(AuthService);
  uiService = inject(UIService);
  route = inject(ActivatedRoute);

  readonly panelOpenState = signal(true);
  viewBy = toSignal<TViewBy>(
    this.route.queryParams.pipe(
      map((params): TViewBy => params['view_by'] || 'trader'),
    ),
  );
  historyTitle = computed(() => {
    if (this.viewBy() === 'mixed') return 'Сравнение рыночных цен';

    if (this.viewBy() === 'recycler') return 'Цены от зернообработчиков';

    return 'Цены объявлений';
  });
  priceAnalysisGroupingList = priceAnalysisGroupingList;

  loading = computed(
    () => this.authService.loading() || this.authService.loadingAuthData(),
  );
  isMobile = this.uiService.isMobile;

  // readonly range = new FormGroup({
  //   start: new FormControl<Date | null>(null),
  //   end: new FormControl<Date | null>(null),
  // });
}
